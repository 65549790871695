/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
          var $body = $("body");
        // JavaScript to be fired on all pages
        $(window).on('load', function() {
          AOS.refresh();
        });
        AOS.init({
          duration: 1200,
          disable: 'mobile'
        });
        
        if ($(".video-popup-checker").length) {
          $("#myModal").css("display", "flex");
          $("body").css("overflow", "hidden");
        }
        var num = 125; //number of pixels before modifying styles
        $(window).bind("scroll", function () {
          if ($(window).scrollTop() > num) {
            $("body").addClass("body-scrolled");
          } else {
            $("body").removeClass("body-scrolled");
          }
        });

        $(".dropdown-menu").removeClass("slider-dropdown");
        $(".menu-side .bar-container").click(function () {
          $(".dropdown-menu").toggleClass("slider-dropdown");
          $(".holder-dropdown").toggleClass("show");
          $(this).toggleClass("open-dropdown");
        });

        $(".sub-arrow").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(this).siblings(".sub-pages").toggleClass("tap");
        });

        var modal = document.getElementById("myModal");

        // Get the button that opens the modal
        var btn = document.getElementById("myBtn");

        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];

        // When the user clicks on the button, open the modal

        $("#popup_module").click(function () {
          $("#myModal").css("display", "flex");
          $("body").css("overflow", "hidden");
        });

        $(".close").click(function () {
          $("#myModal").css("display", "none");
          $("body").css("overflow", "auto");
          $("#myModal").remove();
        });
        if (window.matchMedia('(min-width: 768px)').matches) {
          $(".attractions_overview .col-one:first-child .col-content").addClass("expand");
        } else {
          $(".attractions_overview .col-one:first-child .col-content").removeClass("expand");
        }
         $(".button-attraction").click(function () {
           $(this).parent().parent().toggleClass("expand");
         });
        
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
          if (event.target === modal) {
            modal.style.display = "none";
            $("body").css("overflow", "auto");
          }
        };


        // Get the modal
        var modalBook = document.getElementById("martinModal");
        // Get the button that opens the modal
        var btnBook = document.getElementById("martinButton");
        // Get the <span> element that closes the modal
        var spanBook  = document.getElementsByClassName("close")[0];
        // When the user clicks the button, open the modal 
        btnBook.onclick = function() {
          modalBook.style.display = "block";
          $("body").css("overflow", "hidden");
        }

        // When the user clicks on <span> (x), close the modal
        spanBook.onclick = function() {
          modalBook.style.display = "none";
          $("body").css("overflow", "auto");
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
          if (event.target == modalBook) {
            modalBook.style.display = "none";
            $("body").css("overflow", "auto");
          }
        }




        // Get the modal
        var modalBook2 = document.getElementById("martinModal2");
        // Get the button that opens the modal
        var btnBook2 = document.getElementById("martinButton2");
        // Get the <span> element that closes the modal
        var spanBook2  = document.getElementsByClassName("close2")[0];
        // When the user clicks the button, open the modal 
        btnBook2.onclick = function() {
          modalBook2.style.display = "block";
          $("body").css("overflow", "hidden");
        }

        // When the user clicks on <span> (x), close the modal
        spanBook2.onclick = function() {
          modalBook2.style.display = "none";
          $("body").css("overflow", "auto");
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
          if (event.target == modalBook2) {
            modalBook2.style.display = "none";
            $("body").css("overflow", "auto");
          }
        }


        var btnBook3 = document.getElementById("martinButton3");
        btnBook3.onclick = function() {
          $("body").css("overflow", "hidden");
          $("body").removeClass("menu-off-canvas-active");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
          modalBook.style.display = "block";
          $("body").css("overflow", "hidden");
        }

         $(".js-partners-div").slick({
          dots: false,
          slidesToShow: 4,
          infinite: true,
          arrows: true,
          prevArrow:
          '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
          '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          // fade: true,
          draggable: true,
          adaptiveHeight: true,
          pauseOnHover: true,
          infinite: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
           responsive: [
            {
              breakpoint: 1400,
              settings: {
                dots: false,
                arrows: true,
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                dots: false,
                arrows: true,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                dots: false,
                arrows: true,
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                dots: true,
                arrows: false,
                slidesToShow: 1,
              },
            }
          ],
         });
        
        //slide-for-single-icon
        $(".js-single").slick({
          dots: true,
          infinite: true,
          arrows: false,
          speed: 500,
          autoplay: false,
          autoplaySpeed: 5000,
          asNavFor: ".js-single-icon",
          fade: true,
          draggable: true,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          }
        });
        $(".js-single-icon").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          autoplay: false,
          autoplaySpeed: 5000,
          asNavFor: ".js-single",
          arrows: false,
          dots: false,
          infinite: true,
          draggable: true,
          centerMode: false,
          focusOnSelect: true,
          adaptiveHeight: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2
              },
            }
          ],
        });

        //slide-for-fancy
        $(".js-details").slick({
          dots: true,
          infinite: true,
          arrows: false,
          speed: 500,
          autoplay: false,
          autoplaySpeed: 5000,
          asNavFor: ".js-details-icon",
          fade: true,
          draggable: true,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          }
        });
        $(".js-details-icon").slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 500,
          autoplay: false,
          autoplaySpeed: 5000,
          asNavFor: ".js-details",
          arrows: false,
          dots: false,
          infinite: true,
          draggable: true,
          centerMode: false,
          focusOnSelect: true,
          adaptiveHeight: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2
              },
            }
          ],
        });
        



        $(".js-slider-tour").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          infinite: true,
          asNavFor: ".js-slider-tour-nav",
          adaptiveHeight: true
        });
        $(".js-slider-tour-nav").slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          asNavFor: ".js-slider-tour",
          arrows: false,
          dots: false,
          infinite: true,
          draggable: true,
          centerMode: false,
          focusOnSelect: true,
          adaptiveHeight: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2
              },
            }
          ],
        });
        
        // SLICK HEROES
        $(".js-slick-heroes").slick({
          dots: true,
          infinite: true,
          arrows: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: true,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                dots: true,
                arrows: false,
              },
            },
            {
              breakpoint: 500,
              settings: {
                dots: true,
                arrows: false,
              },
            }
          ],
        });

        $(".js-slick-notifications").slick({
          dots: false,
          infinite: true,
          arrows: false,
          // prevArrow: $('.js-hero-prev'),
          // nextArrow: $('.js-hero-next'),
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
        });

        $(".js-loadmore-posts").click(function () {
          var button = $(this),
            data = {
              action: "loadmore",
              query: loadmore_posts_params.posts, // that's how we get params from wp_localize_script() function
              page: loadmore_posts_params.current_page,
            };

          $.ajax({
            url: loadmore_posts_params.ajaxurl, // AJAX handler
            data: data,
            type: "POST",
            beforeSend: function (xhr) {
              button.html('<span class="button primary">Loading...</span>'); // change the button text, you can also add a preloader image
            },
            success: function (data) {
              if (data) {
                button
                  .html('<span class="button primary">More posts</span>')
                  .before(data); // insert new posts
                loadmore_posts_params.current_page++;

                if (
                  loadmore_posts_params.current_page ===
                  loadmore_posts_params.max_page
                ) {
                  button.remove(); // if last page, remove the button
                }

                // you can also fire the "post-load" event here if you use a plugin that requires it
                // $( document.body ).trigger( 'post-load' );
              } else {
                button.remove(); // if no data, remove the button as well
              }
            },
          });
        });

        $(".js-select2").select2({
          minimumResultsForSearch: Infinity,
        });

        $(".js-select2-search").select2();

        $(".js-selector-with-url").change(function () {
          location.href = $(this).val();
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(".js-jac-account-form").submit(function (e) {
          e.preventDefault();
          var $this = $(this);
          $this.find("button[type=submit]").prop("disabled", true);
          showLoader();
          $.ajax({
            type: $this.attr("method"),
            dataType: "json",
            url: templateJS.ajaxURL,
            data: {
              action: "jac_account_" + $this.data("action"),
              data: $this.serialize(),
            },
            success: function (r) {
              hideLoader();
              $this.find("button[type=submit]").prop("disabled", false);
              if (r.success) {
                if (r.data && r.data.message) {
                  // if don't need success message, remove class at markup
                  $this.find(".js-success-message").text(r.data.message);
                }

                if (r.data && r.data.hidden) {
                  $this.find(".js-fields").hide("slow");
                }
                if (r.data && r.data.disabled) {
                  $this.find("input,button,textarea").prop("disabled", true);
                }

                if (r.data && r.data.url) {
                  if (r.data.url === "#") {
                    location.reload();
                  } else {
                    setTimeout(function () {
                      location.href = r.data.url;
                    }, 3000);
                  }
                }
              } else {
                if (r.data && r.data.message) {
                  $this.find(".js-error-message").text(r.data.message);
                } else {
                  alert("Something wrong, seriously.");
                }
              }
            },
          });
        });

        var $body = $("body");
        // var $fixedheight = $(".menu-fixed").height();
        // $(".wrapper").css("padding-top", $fixedheight + "px");
        //Toggle Filter Options
        $(".js-filter-expand").click(function () {
          $(".js-filter-expander").toggleClass("expanded");
        });

        //Toggle menu
        $(".js-menu-open").click(function () {
          $("body").toggleClass("menu-off-canvas-active");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        $(".js-menu-open-left").click(function () {
          $body.toggleClass("menu-off-canvas-active");
          $body.toggleClass("left");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        //Toggle Feedback
        $(".js-popup-feedback").click(function () {
          $body.toggleClass("popup-active-feedback");
          $body.removeClass("fb-messenger-active");
        });

        //Toggle FB Messenger
        $(".js-fb-messenger").click(function () {
          $body.toggleClass("fb-messenger-active");
          $body.removeClass("menu-off-canvas-active");
        });

        //Toggle Search
        $(".js-popup-search").click(function (e) {
          e.preventDefault();
          $(".popup-wrap-search input").focus();
          $body.toggleClass("popup-active-search");
        });

        $(document).keyup(function (e) {
          if (e.keyCode === 27) {
            // escape key maps to keycode `27`
            $body.removeClass(
              "body-is-scrolled menu-off-canvas-active popup-active-search fb-messenger-active popup-active-feedback left"
            );
          }
        });

        //Toggle accordion links
        $(document).on("click", ".js-accordion-title", function (e) {
          e.preventDefault();
          $(this)
            .closest(".js-accordion")
            .toggleClass("expanded")
            .siblings()
            .removeClass("expanded");
        });

        $(".js-popup-feedback-form").submit(function (e) {
          e.preventDefault();

          var $this = $(this);
          var $message = $this.find(".js-message");

          $.ajax({
            type: "get",
            url: templateJS.ajaxURL,
            dataType: "json",
            data: {
              action: "popup_feedback_submit",
              data: $this.serialize(),
            },
            success: function (r) {
              if (r.success) {
                $this.find("input,textarea,button").prop("disabled", true);
                $message.text(
                  "Successful. This popup will automatically close in 5 seconds."
                );
                setTimeout(function () {
                  $body.toggleClass("popup-active-feedback");
                  $this.find("input,textarea,button").prop("disabled", false);
                  $this.find("input,textarea").val("");
                  $message.text(
                    "Please fill out the form below to leave feedback."
                  );
                }, 5000);
              } else {
                $message.hide("slow", function () {
                  $message.text(r.data.message);
                  $message.show("slow");
                });
              }
            },
          });
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
         // SLICK HEROES
        $(".js-slick-heroes-home").slick({
          dots: true,
          infinite: true,
          arrows: true,
          prevArrow:
          '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
          '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                dots: false,
                arrows: true,
              },
            },
            {
              breakpoint: 500,
              settings: {
                dots: true,
                arrows: false,
              },
            }
          ],
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

jQuery('#down').click(function() {
        jQuery('html, body').animate({
          scrollTop: jQuery('.wrap.content').offset().top,
        }, 1000);
});